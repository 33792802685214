import React from "react"
import HubspotForm from "react-hubspot-form"
import MainContainer from "../components/containers/MainContainer"
import HeadDataNew from "../data/HeadDataNew"

const CreditApp = () => {
  return (
    <MainContainer>
      <HeadDataNew title="Credit Application" description="Credit Application" />
      <div className="w-full xl:max-w-screen-sm px-6 sm:px-10 md:px-24 lg:px-56 xl:px-2 mx-auto my-10">
        <h1 className="text-white text-center">Credit Application</h1>
        <HubspotForm
          region="na1"
          portalId="9298297"
          formId="df4ed023-c612-4c55-8e2f-7425d1a6512c"
          loading={<div className="italic">Loading contact form...</div>}
        />
      </div>
    </MainContainer>
  )
}

export default CreditApp
